import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import link from "../../services/link";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function Location() {
  function getImageLink(link: string) {
    return `https://lh3.googleusercontent.com/d/${link
      .replace("https://drive.google.com/file/d/", "")
      .replace("/view?usp=drive_link", "")}=s1440?authuser=0`;
  }

  const [optionLocation] = useState<any>({
    keyword: "location",
  });
  const listLocation: any = useQuery(
    ["location", optionLocation],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  const [optionLocationMobile] = useState<any>({
    keyword: "locationMobile",
  });
  const listLocationMobile: any = useQuery(
    ["locationMobile", optionLocationMobile],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        maxWidth: "100vw",
        [theme.breakpoints.up("lg")]: { paddingRight: "5vw", paddingLeft: '140px' },
      })}
    >
      <Helmet>
        <title>{lang === "en" ? "Location" : "Lokasi"}</title>
      </Helmet>

      <PageName page={lang === "en" ? "LOCATION" : "LOKASI"} width={lang === "en" ? "250" : "180"} />

      <FormControl sx={{ position: "absolute", top: "20px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
        <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
        <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="id">Indonesia</MenuItem>
        </Select>
      </FormControl>

      <Box
        height="100%"
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.up("lg")]: { width: "calc(100vw - 300px)" },
        })}
      >
        {!listLocation.isLoading && !listLocationMobile.isLoading ? (
          <a
            href={listLocation.data?.data[0].url}
            target="_blank"
            rel="noreferrer"
            style={{ width: "100%" }}
          >
            <picture>
              <source
                media="(min-width:900px)"
                srcSet={getImageLink(listLocation.data?.data[0].link)}
              />
              <img
                src={getImageLink(listLocationMobile.data?.data[0].link)}
                style={{ width: "90%", height: 'auto', maxHeight: '100vh' }}
                alt="Location"
              />
            </picture>
          </a>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
