import axios from "axios";

import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getActivities = async (params: any) => {
  const url = endpoints.ENDPOINT_ACTIVITIES;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const getActivityYears = async (params: any) => {
  const url = endpoints.ENDPOINT_ACTIVITY_YEARS;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const detailActivity = async (id: number) => {
  if (!id) {
    return {};
  }
  const url = endpoints.ENDPOINT_SPECIFIC_ACTIVITIES(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createActivity = async (params: any) => {
  const url = endpoints.ENDPOINT_ACTIVITIES;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editActivity = async (params: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_ACTIVITIES(params.id);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteActivity = async (id: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_ACTIVITIES(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getActivities,
  getActivityYears,
  detailActivity,
  createActivity,
  editActivity,
  deleteActivity
};

export default service;