import {
  Box, FormControl, Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import link from "../../services/link";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function Gallery() {
  function getImageLink(link: string) {
    return `https://lh3.googleusercontent.com/d/${link.replace("https://drive.google.com/file/d/", "").replace("/view?usp=drive_link", "")}=s500?authuser=0`;
  }

  const [optionGallery] = useState<any>({
    keyword: "gallery",
  });
  const listGallery: any = useQuery(
    ["gallery", optionGallery],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
    <Helmet>
      <title>{lang === "en" ? "Gallery" : "Galeri"}</title>
    </Helmet>

    <PageName page={lang === "en" ? "GALLERY" : "GALERI"} width={lang === "en" ? "215" : "185"} />

    <FormControl sx={{ position: "absolute", top: "-60px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
      <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
      <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="id">Indonesia</MenuItem>
      </Select>
    </FormControl>

      <Box sx={{ mt: 15, backgroundColor: "#fbf3dc", padding: "30px" }}>
        <Grid container spacing={1}>
          {!listGallery.isLoading ? listGallery.data?.data.map((gallery:any, i:number) => (
            <Grid item xs={6} sm={3} sx={{ padding: "10px" }}>
              <img
              style={{ width: "90%" }}
                src={getImageLink(gallery.link)}
                alt={`linkDrive${i + 1}`} />
            </Grid>
          )) : <></>}
        </Grid>
      </Box>
    </Box>
  );
}
