import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import service from "../../services/activities";
import queryDefault from "../../utils/queryDefault";
import CustomCarousel from "../components/CustomCarousel2";
import PageName from "../components/PageName";

export default function Activites() {
  const now = new Date()
  const [month, setMonth] = useState(now.getMonth() + 1);
  const [year, setYear] = useState(now.getFullYear());
  const [optPartnership, setOptPartnership] = useState<any>({
    type: 'partnership',
    month: now.getMonth() + 1,
    year: now.getFullYear()
  })

  const [optCommunity, setOptCommunity] = useState<any>({
    type: 'community',
    month: now.getMonth() + 1,
    year: now.getFullYear()
  })

  const [optWorkshop, setOptWorkshop] = useState<any>({
    type: 'workshop',
    month: now.getMonth() + 1,
    year: now.getFullYear()
  })
  const listPartnership: any = useQuery(
    ["partnerships", optPartnership],
    ({ queryKey }) => {
      return service.getActivities({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  const listCommunity: any = useQuery(
    ["communities", optCommunity],
    ({ queryKey }) => {
      return service.getActivities({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  const listWorkshop: any = useQuery(
    ["workshops", optWorkshop],
    ({ queryKey }) => {
      return service.getActivities({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const handleMonthChange = (event: any) => {
    setMonth(event.target.value);
    setOptPartnership((option: any) => ({ ...option, month: event.target.value }));
    setOptCommunity((option: any) => ({ ...option, month: event.target.value }));
    setOptWorkshop((option: any) => ({ ...option, month: event.target.value }));
    listPartnership.refetch();
    listCommunity.refetch();
    listWorkshop.refetch();
  };

  const handleYearChange = (event: any) => {
    setYear(event.target.value);
    setOptPartnership((option: any) => ({ ...option, year: event.target.value }));
    setOptCommunity((option: any) => ({ ...option, year: event.target.value }));
    setOptWorkshop((option: any) => ({ ...option, year: event.target.value }));
    listPartnership.refetch();
    listCommunity.refetch();
    listWorkshop.refetch();
  };

  const yearOptions: number[] = []
  for (var i = 0; i < 10; i++) {
    yearOptions.push(now.getFullYear() - i);
  }

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>{lang === "en" ? "Activites" : "Aktivitas"}</title>
      </Helmet>

      <PageName page={lang === "en" ? "ACTIVITIES" : "AKTIVITAS"} width={lang === "en" ? "255" : "255"} />

      <FormControl sx={{ position: "absolute", top: "20px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
        <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
        <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="id">Indonesia</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ mt: 12, backgroundColor: "#fbf3dc", padding: "30px", width: '100%', paddingLeft: '5%' }}>
        <Grid container spacing={10} sx={{
          justifyContent: "start",
          display: "flex",
          flexDirection: "row"
        }}
        >
          <Grid item xs={12} sm={12} lg={12}>
            <Select
              labelId="select-year-label"
              id="select-year"
              label="Year"
              value={year}
              onChange={handleYearChange}
              sx={{
                backgroundColor: '#d0d76d',
                fontWeight: 'bolder',
                fontSize: '25px',
                borderRadius: '30px',
                border: '3px solid black',
                '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
                '.MuiOutlinedInput-input': { padding: '10px 10px' },
              }}
            >
              {yearOptions.map((year) => {
                return (<MenuItem value={year}>{year}</MenuItem>)
              })}
            </Select>
            <Select
              labelId="select-month-label"
              id="select-month"
              label="Month"
              value={month}
              onChange={handleMonthChange}
              sx={{
                backgroundColor: '#d0d76d',
                fontWeight: 'bolder',
                fontSize: '25px',
                borderRadius: '30px',
                border: '3px solid black',
                '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
                '.MuiOutlinedInput-input': { padding: '10px 10px' },
                marginLeft: '30px'
              }}
            >
              <MenuItem value={1}>{lang === "en" ? "January" : "Januari"}</MenuItem>
              <MenuItem value={2}>{lang === "en" ? "February" : "Februari"}</MenuItem>
              <MenuItem value={3}>{lang === "en" ? "March" : "Maret"}</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>{lang === "en" ? "May" : "Mei"}</MenuItem>
              <MenuItem value={6}>{lang === "en" ? "June" : "Juni"}</MenuItem>
              <MenuItem value={7}>{lang === "en" ? "July" : "Juli"}</MenuItem>
              <MenuItem value={8}>{lang === "en" ? "August" : "Agustus"}</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>{lang === "en" ? "October" : "Oktober"}</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>{lang === "en" ? "December" : "Desember"}</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3, backgroundColor: "#f4e4cb", padding: "30px", width: '90%', border: '3px solid black', textAlign: 'center' }}>
        <Grid container spacing={10} sx={{
          justifyContent: "start",
          display: "flex",
          flexDirection: "row"
        }}
        >
          <Grid item xs={4} sm={4} lg={4}>
            <Typography
              variant="h1"
              fontSize="22px"
              fontWeight="700"
              fontFamily={"SaintRegusSemiBold"}
              letterSpacing={-2}
              sx={{ textShadow: "-2px 2px #000", WebkitTextStroke: "1px black", color: "#59c3b3" }}>
              {lang === "en" ? "Event Partnership" : "Acara Kemitraan"}
            </Typography>
            {!listPartnership.isLoading ? <CustomCarousel images={listPartnership.data?.data} /> : <></>}
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <Typography
              variant="h1"
              fontSize="22px"
              fontWeight="700"
              fontFamily={"SaintRegusSemiBold"}
              letterSpacing={-2}
              sx={{ textShadow: "-2px 2px #000", WebkitTextStroke: "1px black", color: "#59c3b3" }}>
              {lang === "en" ? "Event Community" : "Acara Komunitas"}
            </Typography>
            {!listCommunity.isLoading ? <CustomCarousel images={listCommunity.data?.data} /> : <></>}
          </Grid>
          <Grid item xs={4} sm={4} lg={4}>
            <Typography
              variant="h1"
              fontSize="22px"
              fontWeight="700"
              fontFamily={"SaintRegusSemiBold"}
              letterSpacing={-2}
              sx={{ textShadow: "-2px 2px #000", WebkitTextStroke: "1px black", color: "#59c3b3" }}>
              {lang === "en" ? "Event Workshop" : "Loka Karya"}
            </Typography>
            {!listWorkshop.isLoading ? <CustomCarousel images={listWorkshop.data?.data} /> : <></>}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
