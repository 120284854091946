const sideMenu = [
  {
    id: "home",
    label: "Home",
    label_id: "Beranda",
    href: "/home",
    permissions: [1]
  },
  {
    id: "product",
    label: "Product",
    label_id: "Produk",
    href: "/product",
    permissions: [1, 2]
  },
  {
    id: "location",
    label: "Location",
    label_id: "Lokasi",
    href: "/location",
    permissions: [1, 2]
  },
  {
    id: "client",
    label: "Client & Partners",
    label_id: "Klien & Partner",
    href: "/client",
    permissions: [1, 3]
  },
  {
    id: "relations",
    label: "Investor Relations",
    label_id: "Relasi Investor",
    href: "/relations",
    permissions: [1, 4]
  },
  {
    id: "companyInfo",
    label: "Company Info",
    label_id: "Info Perusahaan",
    href: "/company-info",
    permissions: [1, 4]
  },
  {
    id: "governance",
    label: "Governance",
    label_id: "Tata Kelola",
    href: "/governance",
    permissions: [1, 4]
  },
  {
    id: "activity",
    label: "Activities",
    label_id: "Aktivitas",
    href: "/activities",
    permissions: [99]
  },
  {
    id: "gallery",
    label: "Gallery",
    label_id: "Galeri",
    href: "/gallery",
    permissions: [99]
  },
  {
    id: "career",
    label: "Career",
    label_id: "Karir",
    href: "/career",
    permissions: [99]
  },
  {
    id: "customerCare",
    label: "Customer Care",
    label_id: "Layanan Pelanggan",
    href: "/customer-care",
    permissions: [99]
  }
];

export default sideMenu;
