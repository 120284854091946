import {
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import get from "lodash/get";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useAlert from "../../hooks/useAlert";
import useNotification from "../../hooks/useNotification";
import link from "../../services/link";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function GalleryAdmin() {
  function getImageLink(link: string) {
    return `https://lh3.googleusercontent.com/d/${link.replace("https://drive.google.com/file/d/", "").replace("/view?usp=drive_link", "")}=s250?authuser=0`;
  }
  const [optionGallery] = useState<any>({
    keyword: "gallery",
  });
  const listGallery: any = useQuery(
    ["investor", optionGallery],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const alert = useAlert();
  const notification = useNotification();
  const deleteLink = useMutation("delete-link", link.deleteLink, {
    onSuccess: () => {
      listGallery.refetch();
      notification.onOpen({
        message: "Gallery image was successfully deleted!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const onDelete = (id: number) => {
    alert.onClose();
    deleteLink.mutate(id);
  };
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete this Gallery image?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Admin Gallery</title>
      </Helmet>

      <PageName page="GALLERY" width="215" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
          <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight
            loading={listGallery.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listGallery, "data.data", [])}
            rowCount={get(listGallery, "data.data", []).length}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "link",
                headerName: "Image Source",
                flex: 1,
                filterable: false,
                minWidth: 200,

                renderCell: (params) => {
                  return (
                    <Stack>
                      <a href={params.value} target="_blank" rel="noreferrer">Source</a>
                      <br></br>
                      <Typography>Preview:</Typography>
                      <img src={getImageLink(params.value)} style={{ width: "50%" }} alt="Banner" />
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/admin/gallery/edit-gallery/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onClickDelete(params.row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
        <Box sx={{ mt: 2, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/admin/gallery/add-gallery"
            >
              Add Gallery Image
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
