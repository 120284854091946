import axios from "axios";

import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getInvestors = async (params: any) => {
  const url = endpoints.ENDPOINT_INVESTOR;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const detailInvestor = async (id: number) => {
  if (!id) {
    return {};
  }
  const url = endpoints.ENDPOINT_SPECIFIC_INVESTOR(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createInvestor = async (params: any) => {
  const url = endpoints.ENDPOINT_INVESTOR;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editInvestor = async (params: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_INVESTOR(params.id);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteInvestor = async (id: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_INVESTOR(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getInvestors,
  detailInvestor,
  createInvestor,
  editInvestor,
  deleteInvestor
};

export default service;