import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  TextField
} from "@mui/material";
import { get } from "lodash";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import investor from "../../services/investor";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function InvestorAdminCreateEdit() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    title: yup.string().required("Title is a required field"),
  });
  const currentInvestor = useQuery("view-link", () =>
    investor.detailInvestor(Number(id))
  );
  const form = useForm({
    defaultValues: {
      title: "",
      link: "",
      menu: "",
      title_id: "",
    },
    resolver: yupResolver(schema),
  });

  const createInvestor = useMutation("investor", investor.createInvestor, {
    onSuccess: (response) => {
      navigate("/admin/investor/");

      notification.onOpen({
        message:
          response.message === ""
            ? "Investor Relation was successfully created!"
            : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message:
          error?.response?.data?.message === undefined
            ? "Something went wrong"
            : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateInvestor = useMutation("update-investor", investor.editInvestor, {
    onSuccess: (response) => {
      navigate("/admin/investor/");

      notification.onOpen({
        message:
          response.message === ""
            ? "Investor Relation was successfully updated!"
            : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message:
          error?.response?.data?.message === undefined
            ? "Something went wrong"
            : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    if (id) {
      updateInvestor.mutate({
        data: {
          ...values,
          id: id,
        },
      });
    } else {
      createInvestor.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (id) {
      form.setValue("title", get(currentInvestor, "data.data.title"));
      form.setValue("title_id", get(currentInvestor, "data.data.title_id"));
      form.setValue("link", get(currentInvestor, "data.data.link"));
      form.setValue("menu", get(currentInvestor, "data.data.menu"));
    }
  }, [currentInvestor, form, id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw",
      }}
    >
      <Helmet>
        <title>Create or Edit Investor Relations</title>
      </Helmet>

      <PageName page="INVESTOR RELATIONS" width="460" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        {!currentInvestor.isLoading ? (
          <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel required>Title</FormLabel>
                  <Controller
                    name="title"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel required>Judul</FormLabel>
                  <Controller
                    name="title_id"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Menu</FormLabel>
                  <Controller
                    name="menu"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        select
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      >
                        <MenuItem key="investor" value="investor">Investor</MenuItem>
                        <MenuItem key="company" value="company">Company Info</MenuItem>
                        <MenuItem key="governance" value="governance">Governance</MenuItem>
                      </TextField>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Link URL</FormLabel>
                  <Controller
                    name="link"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={id ? updateInvestor.isLoading : createInvestor.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
