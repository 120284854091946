import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import { get } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import link from "../../services/link";
import queryDefault from "../../utils/queryDefault";
import { Helmet } from "react-helmet-async";
import PageName from "../components/PageName";

export default function LocationAdminEdit() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    link: yup.string().required("Image source is a required field"),
  });
  const currentLink = useQuery("view-link", () => link.detailLink(Number(id)));
  const form = useForm({
    defaultValues: {
      page: "location",
      link: "",
      url: ""
    },
    resolver: yupResolver(schema),
  });

  const updateLink = useMutation("update-link", link.editLink, {
    onSuccess: (response) => {
      navigate("/admin/location/");

      notification.onOpen({
        message: response.message === "" ? "Image was successfully updated!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    updateLink.mutate({
      data: {
        ...values,
        id: id
      }
    })
  };

  useEffect(() => {
    if (id) {
      form.setValue("page", "location");
      form.setValue("link", get(currentLink, "data.data.link"));
      form.setValue("url", get(currentLink, "data.data.url"));
    }
  }, [currentLink, form, id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Edit Location Image</title>
      </Helmet>

      <PageName page="LOCATION" width="250" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        {!currentLink.isLoading ? (
          <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel required>Image Source</FormLabel>
                  <Controller
                    name="link"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>Link URL</FormLabel>
                  <Controller
                    name="url"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>) : <></>}
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={updateLink.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
