import {
  Box,
  Button
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import PageName from "../components/PageName";

export default function ActivitiesAdmin() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Activites Admin</title>
      </Helmet>

      <PageName page="ACTIVITIES" width="255" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
          <Button variant="contained"
            onClick={() => navigate(`/admin/activities/partnership`)}>
            Partnership
          </Button>
        </Box>
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <Button variant="contained"
            onClick={() => navigate(`/admin/activities/community`)}>
            Community
          </Button>
        </Box>
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <Button variant="contained"
            onClick={() => navigate(`/admin/activities/workshop`)}>
            Workshop
          </Button>
        </Box>
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <Button variant="outlined"
            onClick={() => navigate(`/admin/activities/add-activity`)}>
            Add Activity
          </Button>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
