import axios from "axios";

import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const listCustomerCare = async (params: any) => {
  const url = endpoints.ENDPOINT_CUSTOMER_CARE;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const detailCustomerCare = async (id: number) => {
  if (!id) {
    return {};
  }
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const detailCustomerCareSection = async (id: number) => {
  if (!id) {
    return {};
  }
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE_SECTION(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const detailCustomerCareLink = async (id: number) => {
  if (!id) {
    return {};
  }
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE_LINK(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editCustomerCare = async (params: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE(params?.id);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editCustomerCareSection = async (params: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE_SECTION(params?.id);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editCustomerCareLink = async (params: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE_LINK(params?.id);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createCustomerCare = async (params: any) => {
  const url = endpoints.ENDPOINT_CUSTOMER_CARE;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createCustomerCareSection = async (params: any) => {
  const url = endpoints.ENDPOINT_CUSTOMER_CARE_SECTION;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createCustomerCareLink = async (params: any) => {
  const url = endpoints.ENDPOINT_CUSTOMER_CARE_LINK;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteCustomerCare = async (id: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteCustomerCareSection = async (id: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE_SECTION(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteCustomerCareLink = async (id: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_CUSTOMER_CARE_LINK(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  listCustomerCare,
  detailCustomerCare,
  detailCustomerCareSection,
  detailCustomerCareLink,
  editCustomerCare,
  editCustomerCareSection,
  editCustomerCareLink,
  createCustomerCare,
  createCustomerCareSection,
  createCustomerCareLink,
  deleteCustomerCare,
  deleteCustomerCareSection,
  deleteCustomerCareLink
};

export default service;