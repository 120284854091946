
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';

const CustomCarousel = ({ images }: any) => {
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);
  function getImageLink(link: string) {
    return `https://lh3.googleusercontent.com/d/${link.replace("https://drive.google.com/file/d/", "").replace("/view?usp=drive_link", "")}=s1000?authuser=0`;
  }
  if (images.length == 0) {
    return <></>
  }
  return (
    <>
      <img
        src={getImageLink(images[0].link)}
        alt={`Slide 0`}
        style={{ display: "none" }}
        onLoad={() => setFirstImgLoaded(true)}
      />
      {firstImgLoaded && (
        <Carousel
          animation='slide'
          autoPlay
          navButtonsAlwaysVisible
          stopAutoPlayOnHover
          indicators
          navButtonsProps={
            {
              style: {
                opacity: 0.6
              }
            }
          }
          indicatorContainerProps={{
            style: {
              marginTop: '-30px',
              position: 'relative',
              height: '30px',
              zIndex: 1
            }
          }}
          interval={5000}
        >
          {images.map((element: any, i: number) => (
            <a rel="noreferrer" onClick={() => {
              if (element.url != "") {
                window.open(element.url);
              }
            }} key={i} style={{ zIndex: "-5" }}>
              <img
                src={getImageLink(element.link)}
                alt={`Slide ${i}`}
                style={{ width: `100%`, cursor: "pointer" }}
                className="carousel__img"
              />
            </a>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default CustomCarousel;