import { Search } from "@mui/icons-material";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CookieJs from "js-cookie";
import { debounce } from 'lodash';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import useNotification from "../hooks/useNotification";
import careerService from "../services/career";
import PageName from "./components/PageName";

export default function Career() {

  const debounceSearch = debounce((value) => {
    setSearch({ ...search, keyword: value })
  }, 1000)

  const debounceLocation = debounce((value) => {
    setSearch({ ...search, location: value })
  }, 1000)

  const handleChangeKeyword = (event: any) => {
    debounceSearch(event.target.value)
  }

  const [search, setSearch] = useState({
    keyword: "",
    location: "",
    position: "",
    contract: "",
  });
  const [input] = useState("");
  const [jobs, setJobs] = useState([
    {
      id: "",
      title: "",
      desc: "",
      desc_id: "",
      location: "",
      position: "",
      workingtype: "",
      contract: "",
      deadline: "",
      linkedin: "",
    },
  ]);
  const notification = useNotification();
  useEffect(() => {
    careerService
      .getAllCareer(search)
      .then((data) => {
        setJobs(data.data);
      })
      .catch((error) => {
        notification.onOpen({
          message: "Something went wrong!",
          type: "error",
          position: "top",
        });
      });
  }, [notification, search]);

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={(theme) => ({
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        maxHeight: "100vh",
        [theme.breakpoints.up("md")]: { paddingRight: "5vw", marginTop: "20px" },
      })}
    >
      <Helmet>
        <title>{lang === "en" ? "Career" : "Karir"}</title>
      </Helmet>

      <PageName page={lang === "en" ? "CAREER" : "KARIR"} width={lang === "en" ? "200" : "180"} />

      <FormControl sx={{ position: "absolute", top: "20px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
        <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
        <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="id">Indonesia</MenuItem>
        </Select>
      </FormControl>

      <Box
        width="100%"
        sx={(theme) => ({
          backgroundColor: "#fbf3dc",
          [theme.breakpoints.up("lg")]: { height: "80px" },
        })}
      ></Box>

      <Box
        sx={(theme) => ({
          backgroundColor: "#fbf3dc",
          padding: "30px",
          [theme.breakpoints.up("lg")]: { mx: 5 },
          overflow: "auto",
        })}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: "#C6D25A",
            width: "100vw",
            maxWidth: "100%",
            textAlign: "center",
            padding: "10px",
            border: "2px solid black",
          })}
        >
          <Typography
            variant="h1"
            fontSize="40px"
            fontWeight="700"
            color={"#FD7475"}
            fontFamily={"SaintRegusSemiBold"}
            sx={{ textShadow: "-4px 2px #000", WebkitTextStroke: "1.5px black" }}
          >
            {lang === "en" ? "CURRENT VACANCIES" : "LOWONGAN KERJA"}
          </Typography>
          <Typography variant="h2" fontWeight="bold" fontFamily={"SaintRegus"}>
          {lang === "en" ? "Explore our current job opportunities!" : "Jelajahi lowongan kerja kami saat ini!"}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F2DE89",
            boxShadow: "-7px 7px 0px 0px #4cb8a6",
            width: "calc(100vw - 7px)",
            maxWidth: "calc(100% - 7px)",
            marginLeft: "7px",
            border: "2px solid black",
            mt: 1
          }}
        >
          <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <Input
              id="standard-adornment-amount"
              placeholder={lang === "en" ? "Search for jobs..." : "Cari pekerjaan..."}
              onChange={handleChangeKeyword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="search"
                    onClick={() => setSearch({ ...search, keyword: input })}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              disableUnderline
            // sx={}
            />
          </FormControl>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="calc(100vw - 7px)"
          maxWidth="calc(100% - 7px)"
          marginLeft="7px"
          mb={2}
        >
          <Grid item xs={5} md={3} mt={2}>
            <Box
              sx={{
                backgroundColor: "#F2DE89",
                boxShadow: "-7px 7px 0px 0px #4cb8a6",
                border: "2px solid black"
              }}
            >
              <TextField
                fullWidth
                placeholder={lang === "en" ? "Contract Type" : "Tipe Kontrak"}
                id="demo-customized-select"
                label={lang === "en" ? "Contract Type" : "Tipe Kontrak"}
                select
                onChange={(event) =>
                  setSearch({ ...search, contract: event.target.value })
                }
                sx={{
                  '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="Full-time">Full-time</MenuItem>
                <MenuItem value="Part-time">Part-time</MenuItem>
                <MenuItem value="Internship">Internship</MenuItem>
                <MenuItem value="Freelance">Freelance</MenuItem>
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={5} md={3} mt={2}>
            <Box
              sx={{
                backgroundColor: "#F2DE89",
                boxShadow: "-7px 7px 0px 0px #4cb8a6",
                border: "2px solid black",
              }}
            >
              <TextField
                fullWidth
                placeholder={lang === "en" ? "Locations" : "Lokasi"}
                id="demo-customized-select"
                label={lang === "en" ? "Locations" : "Lokasi"}
                onChange={(event) =>
                  debounceLocation(event.target.value)
                }
                sx={{
                  '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
                }}
              >
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} mt={2}>
            <Box
              sx={{
                backgroundColor: "#F2DE89",
                boxShadow: "-7px 7px 0px 0px #4cb8a6",
                border: "2px solid black",
              }}
            >
              <TextField
                fullWidth
                placeholder={lang === "en" ? "Position Level" : "Level Posisi"}
                id="demo-customized-select"
                label={lang === "en" ? "Position Level" : "Level Posisi"}
                select
                onChange={(event) =>
                  setSearch({ ...search, position: event.target.value })
                }
                sx={{
                  '.MuiOutlinedInput-notchedOutline': { border: '0 !important' },
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="Student/Internship">
                  Student/Internship
                </MenuItem>
                <MenuItem value="Entry Level">Entry Level</MenuItem>
                <MenuItem value="Associate">Associate</MenuItem>
                <MenuItem value="Mid-Senior Level">Mid-Senior Level</MenuItem>
              </TextField>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            overflowX: "hidden",
            overflowY: "scroll",
            maxHeight: "40vh"
          }}
        >
          {jobs.length > 0 ? (
            jobs.map((item) => (
              <a key={item.id} href={item.linkedin} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                <Box
                  sx={{
                    backgroundColor: "#F4EBDA",
                    border: "2px solid black",
                    padding: "15px",
                    marginTop: "20px",
                  }}
                >
                  <Box sx={{
                    marginBottom: "-10px"
                  }}>
                    <Typography
                      variant="h1"
                      fontSize="30px"
                      fontWeight="700"
                      color={"#ee879f"}
                      fontFamily={"SaintRegusSemiBold"}
                      sx={{
                        textShadow: "-2px 2px #000",
                        WebkitTextStroke: "1px black",
                        paddingBottom: '0'
                      }}
                    >
                      {item.title}
                    </Typography>
                    <br />
                  </Box>
                  <Box sx={{ color: 'black', marginBottom: "-15px" }}>
                    <Typography variant="h5" align="justify" sx={{ marginBottom: "-10px" }}>
                      {lang === "en" ? item.desc : item.desc_id}
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      <span style={{ fontWeight: "bold" }}>{lang === "en" ? "Position" : "Posisi"}: </span>
                      {item.position}
                    </Typography>
                    <Typography variant="h5">
                      <span style={{ fontWeight: "bold" }}>{lang === "en" ? "Location" : "Lokasi"}: </span>
                      {item.location} ({item.workingtype}-{item.contract})
                    </Typography>
                    <Typography variant="h5">
                      <span style={{ fontWeight: "bold" }}>{lang === "en" ? "Deadline" : "Tenggat Waktu"}: </span>
                      {item.deadline.split('T')[0]}
                    </Typography>
                    <br />
                  </Box>
                </Box>
              </a>
            ))
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={3}
            >
              <Typography variant="h4">{lang === "en" ? "No Jobs Available" : "Tidak Ada Pekerjaan Untuk Sekarang"}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
