import { MenuOpen } from "@mui/icons-material";
import CookieJs from "js-cookie";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemBaseProps,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useDispatch";
import { useAppSelector } from "../../hooks/useSelector";
import { actions } from "../../store/reducers/global";
import sideMenu from "./sidebarMenu";
import adminSideMenu from "./adminSideMenu";
// assets
import Logo from "../../assets/images/logo_photomatics.png";
import Square from "../../assets/images/Square_Yellow.png";
import InstagramLogo from "../../assets/images/social/instagram.png";
import TiktokLogo from "../../assets/images/social/tik-tok.png";
import TwitterLogo from "../../assets/images/social/twitter.png";

interface ListItemProps extends ListItemBaseProps {
  label: string;
  href: string;
  itemId: string;
}

const ListItem = (props: ListItemProps) => {
  const { label, href, itemId, ...others } = props;
  const { menuOpen } = useAppSelector((state: { global: any }) => state.global);
  const dispatch = useAppDispatch();
  const selected = menuOpen.findIndex((id: any) => id === itemId) > -1;
  const itemHandler = () => {
    dispatch(actions.openMenu(itemId));
    dispatch(actions.closeSidebar());
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === itemId);
    if (currentIndex > -1) {
      dispatch(actions.openMenu(itemId));
    }
    // eslint-disable-next-line
  }, [document.location.pathname]);

  return (
    <ListItemButton
      {...others}
      component={RouterLink}
      to={href}
      selected={selected}
      sx={{ mx: 3, borderRadius: "8px", ...others?.sx }}
      onClick={itemHandler}
    >
      <ListItemText
        primary={
          <Box
            sx={{
              boxShadow: "-4px 4px 0px 0px rgba(0,0,0,1)",
              border: "3px solid black",
              borderRadius: "30px",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              margin: "-8px 0",
            }}
            bgcolor={!selected ? "#D0D76D" : "#F499A9"}
          >
            <Typography
              variant="body2"
              fontWeight="400"
              color={"black"}
              fontFamily={"SaintRegus"}
              fontSize={"1.5vh"}
            >
              {label}
            </Typography>
          </Box>
        }
      />
    </ListItemButton>
  );
};

export default function DrawerSidebar() {
  const dispatch = useAppDispatch();
  const { sidebar } = useAppSelector((state: any) => state.global);
  const token = CookieJs.get("ID_USER_ACCOUNT");
  const admin = window.location.href.indexOf("admin");

  const [lang, setLang] = useState("en");

  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang === undefined) {
      CookieJs.set("lang", "en");
      setLang("en");
    } else {
      setLang(cookieLang);
    }
  }, []);

  const onLogout = () => {
    CookieJs.remove("ID_USER_ACCOUNT");
    CookieJs.remove("ID_USER");
    CookieJs.remove("USER");
    window.location.href = "/admin/login";
  };

  const renderSideMenu = useCallback(
    (item: any, i: any) => {
      return (
        <ListItem
          key={i}
          label={lang === "en" || admin ? item.label : item.label_id}
          itemId={item.id}
          href={item.href}
          sx={{ maxHeight: "5vh", my: 0.5 }}
        />
      );
    },
    [lang, admin]
  );

  return (
    <Box>
      <Box
        sx={(theme) => ({
          width: "300px",
          flexShrink: 0,
          position: "fixed",
          left: 0,
          bottom: 0,
          height: "100vh",
          overflowY: "auto",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
          display: "inline-flex",
        })}
      >
        <Box
          sx={{
            backgroundColor: "#669CD5",
            borderRightWidth: 2,
            borderRightColor: "black",
            borderRightStyle: "solid",
            height: "100%",
            width: "97%",
          }}
        >
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <img
              src={Logo}
              style={{ maxHeight: "13vh", margin: "auto", maxWidth: "80%" }}
              alt="Photomatics Logo"
            />
          </Box>
          <Box>
            <List>
              {admin >= 0
                ? adminSideMenu.map(renderSideMenu)
                : sideMenu.map(renderSideMenu)}
            </List>
          </Box>
          {admin < 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: "60px",
                width: "96.5%",
              }}
            >
              <a
                href="https://www.instagram.com/photomaticsid/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={InstagramLogo}
                  style={{ width: "25px", marginRight: "10px" }}
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.tiktok.com/@photomatics"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={TiktokLogo}
                  style={{ width: "25px", marginRight: "10px" }}
                  alt="tiktok"
                />
              </a>
              <a
                href="https://twitter.com/photomaticsid"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={TwitterLogo}
                  style={{ width: "25px" }}
                  alt="twitter"
                />
              </a>
            </Box>
          )}
          {admin < 0 && (
            <img
              src={Square}
              style={{
                zIndex: "99",
                width: "96.5%",
                position: "absolute",
                bottom: 0,
              }}
              alt="Burger"
            />
          )}
          <Box display="flex" justifyContent="center" mt={5}>
            {token && admin >= 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onLogout}
                sx={{ border: "1px solid black" }}
              >
                Logout
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F499A9",
            borderRightWidth: 2,
            borderRightColor: "black",
            borderRightStyle: "solid",
            pt: 2,
            height: "100%",
            width: "3%",
          }}
        ></Box>
      </Box>

      <Drawer
        anchor="left"
        open={sidebar}
        onClose={() => dispatch(actions.closeSidebar())}
      >
        <Box sx={{ width: 250, backgroundColor: "#669CD5" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 1 }}
          >
            <RouterLink to="/">
              <img src={Logo} style={{ height: 24 }} alt="home" />
            </RouterLink>

            <IconButton
              size="small"
              color="primary"
              onClick={() => dispatch(actions.closeSidebar())}
            >
              <MenuOpen />
            </IconButton>
          </Stack>

          <Box>
            <List>
              {admin >= 0
                ? adminSideMenu.map(renderSideMenu)
                : sideMenu.map(renderSideMenu)}
            </List>
          </Box>
          <Box display="flex" justifyContent="center" mt={5}>
            {token && admin > 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onLogout}
                sx={{ border: "1px solid black" }}
              >
                Logout
              </Button>
            )}
          </Box>

          {admin < 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: "60px",
                width: "96.5%",
              }}
            >
              <a
                href="https://www.instagram.com/photomaticsid/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={InstagramLogo}
                  style={{ width: "25px", marginRight: "10px" }}
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.tiktok.com/@photomatics"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={TiktokLogo}
                  style={{ width: "25px", marginRight: "10px" }}
                  alt="tiktok"
                />
              </a>
              <a
                href="https://twitter.com/photomaticsid"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={TwitterLogo}
                  style={{ width: "25px" }}
                  alt="twitter"
                />
              </a>
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}
