import {
  Menu as MenuIcon
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Theme,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";


// assets
import { useAppDispatch } from "../../hooks/useDispatch";
import { actions } from "../../store/reducers/global";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: "100%",
    backgroundColor: "#669CD5",
    borderBottom: "1px solid #d3c9e0",
    position: "fixed",
    top: 0,
    zIndex: 99,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 768,
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const page = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  return (
    <Box className={classes.header} sx={{ px: 2, py: 1 }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => dispatch(actions.openSidebar())}
            sx={{ display: { lg: "none", md: "block" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h2">{`${page.charAt(0).toUpperCase()}${page.slice(1)}`}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
