import {
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import get from "lodash/get";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useAlert from "../../hooks/useAlert";
import useNotification from "../../hooks/useNotification";
import service from "../../services/customerCare";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function CustomerCareDetailAdmin() {
  const { id } = useParams();
  const detail: any = useQuery("section", () => service.detailCustomerCare(Number(id)));
  const alert = useAlert();
  const notification = useNotification();
  const deleteSection = useMutation("delete-section", service.deleteCustomerCareSection, {
    onSuccess: () => {
      detail.refetch();
      notification.onOpen({
        message: "Section was successfully deleted!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const onDelete = (id: number) => {
    alert.onClose();
    deleteSection.mutate(id);
  };
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete this Section?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const navigate = useNavigate();
  const handleOnCellClick = (params: GridCellParams) => {
    const { row } = params;
    navigate(`/admin/customer-care/section/${row.id}`, { state: row });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Admin Detail Customer Care</title>
      </Helmet>

      <PageName page="CUSTOMER CARE" width="380" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
          <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight
            loading={detail.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(detail, "data.data.sections", [])}
            rowCount={get(detail, "data.data.sections", []).length}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "title",
                headerName: "Section Title",
                flex: 1,
                filterable: false,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <Stack onClick={() => handleOnCellClick(params)}>
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "title_id",
                headerName: "Judul",
                flex: 1,
                filterable: false,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <Stack onClick={() => handleOnCellClick(params)}>
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/admin/customer-care/edit-section/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onClickDelete(params.row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
        <Box sx={{ mt: 2, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              component={RouterLink}
              to={`/admin/customer-care/add-section/${id}`}
            >
              Add Section
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
