import axios from "axios";

import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAllCareer = async (params: any) => {
  const url = endpoints.API + '/career';

  const { data: response } = await axios.get(url, {
    params: params
  });

  return response;
};

const createCareer = async (params: any) => {
  const url = endpoints.API + '/career';

  const { data: response } = await axios.post(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editCareer = async (params: any) => {
  const url = endpoints.API + '/career/' + params?.data?.id

  const { data: response } = await axios.patch(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteCareer = async (userId: any) => {
  const url = endpoints.API + '/career/' + userId

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const career = {
    getAllCareer,
    deleteCareer,
    editCareer,
    createCareer
}

export default career;