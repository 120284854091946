import { Box, Typography, Theme } from '@mui/material';
import Star from "../../assets/images/Star_Blue.png";
import { makeStyles } from "@mui/styles";
import SquarePink from "../../assets/images/Square_Pink.png";


const useStyles = makeStyles((theme: Theme) => ({
  pageName: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  },
}));

const PageName = ({ page, width }: any) => {
  const classes = useStyles();
  const calculatedWidth = `${width}px`;
  const starLeft = `${Number(width) + 25 + 299}px`;
  return (
    <Box className={classes.pageName}>
      <Box
        position="fixed"
        top="30px"
        left="299px"
        bgcolor="#F499A9"
        width={calculatedWidth}
        padding="5px"
        paddingLeft="10px"
        borderRadius="0 50px 50px 0"
        border="1px solid black"
        zIndex="11"
        sx={{ boxShadow: "0 4px 0px 0px rgba(0,0,0,1)" }}
      >
        <Typography
          variant="h1"
          fontSize="40px"
          fontWeight="700"
          color={"white"}
          fontFamily={"Poppins"}
          sx={{ textShadow: "-2px 2px #000", WebkitTextStroke: "1px black" }}
        >
          {page}
        </Typography>
      </Box>
      <img src={Star} style={{ width: "50px", position: "fixed", top: "30px", left: `${starLeft}` }}></img>
      <img
        src={SquarePink}
        style={{
          zIndex: "99",
          width: "5vw",
          minHeight: "100vh",
          position: "fixed",
          right: 0,
          top: 0
        }}
        alt="Burger"
      />
    </Box>
  );
};
export default PageName;