import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import careerService from "../services/career";
import useNotification from "../hooks/useNotification";

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { randomCreatedDate, randomId } from "@mui/x-data-grid-generator";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import PageName from "./components/PageName";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";

const initialRows: GridRowsProp = [
  {
    id: randomId(),
    title: "Marketing Officer",
    desc: "Photomatics is the automatic photo machine service company with an 80s branding style. Since 2020, we have located in more than 25 cities for total 100 branches, partnering with coffee shop and communal spaces. We are looking for Marketing Officer position that responsible for marketing activities throughout social media and partnership to increasing brand awareness..",
    desc_id: "Photomatics adalah",
    location: "Jakarta",
    position: "Associate",
    workingType: "WFO",
    contract: "Full-time",
    linkedin: "http://google.com",
    deadline: randomCreatedDate(),
  },
  {
    id: randomId(),
    title: "Crew Photomatics Officer",
    desc: "Photomatics is the automatic photo machine service company with an 80s branding style. Since 2020, we have located in more than 25 cities for total 100 branches, partnering with coffee shop and communal spaces. We are looking for Marketing Officer contract that responsible for marketing activities throughout social media and partnership to increasing brand awareness..",
    desc_id: "Photomatics adalah",
    location: "Jakarta",
    position: "Mid-Senior Level",
    workingType: "WFH",
    linkedin: "http://google.com",
    contract: "Full-time",
    deadline: randomCreatedDate(),
  },
];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      { id, title: "", desc: "", desc_id: "", isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "title" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = React.useState(initialRows);
  const notification = useNotification();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  useEffect(() => {
    careerService
      .getAllCareer({ keyword: "" })
      .then((data) => {
        setRows(data.data);
      })
      .catch((error) => {
        notification.onOpen({
          message: "Something went wrong!",
          type: "error",
          position: "top",
        });
      });
  }, []);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    careerService.deleteCareer(id).then((res) => {
      setRows(rows.filter((row) => row.id !== id));
      notification.onOpen({
        message: "Success Delete",
        type: "success",
        position: "top",
      });
    }).catch((error) => {
      notification.onOpen({
        message: "Something went wrong!",
        type: "error",
        position: "top",
      });
    })
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    if (updatedRow.isNew) {
      careerService
        .createCareer({
          data: updatedRow
        })
        .then((res) => {
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          notification.onOpen({
            message: "Success Create",
            type: "success",
            position: "top",
          });
        })
        .catch((error) => {
          notification.onOpen({
            message: "Something went wrong!",
            type: "error",
            position: "top",
          });
        });
    } else {
      careerService
        .editCareer({
          data: updatedRow
        })
        .then((res) => {
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          notification.onOpen({
            message: "Success Update",
            type: "success",
            position: "top",
          });
        })
        .catch((error) => {
          notification.onOpen({
            message: "Something went wrong!",
            type: "error",
            position: "top",
          });
        });
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", width: 180, editable: true },
    {
      field: "desc",
      headerName: "Description",
      width: 180,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "desc_id",
      headerName: "Deskripsi",
      width: 180,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "workingtype",
      headerName: "Working Type",
      width: 180,
      editable: true,
      type: "singleSelect",
      valueOptions: ["WFH", "WFO", "Hybrid"],
    },
    {
      field: "location",
      headerName: "Location",
      width: 180,
      editable: true,
    },
    {
      field: "position",
      headerName: "Position Level",
      width: 180,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Student/Internship",
        "Entry Level",
        "Associate",
        "Mid-Senior Level",
      ],
    },
    {
      field: "contract",
      headerName: "Contract Type",
      width: 180,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Full-time", "Part-time", "Internship", "Freelance"],
    },
    {
      field: "deadline",
      headerName: "Deadline",
      type: "date",
      width: 180,
      editable: true,
      valueGetter: (params) => {
        if (params.value) {
          var date = new Date(params.value);
          var userTimezoneOffset = date.getTimezoneOffset() * 60000;
          return new Date(date.getTime() - userTimezoneOffset);
        }
      },
    },
    {
      field: "linkedin",
      headerName: "Linkedin",
      width: 180,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw",
      }}
    >
      <Helmet>
        <title>Admin Career</title>
      </Helmet>

      <PageName page="CAREER" width="200" />

      <Box height="80px" width="100%" sx={{ backgroundColor: "#fbf3dc" }}></Box>
      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box
          sx={(theme) => ({
            backgroundColor: "#fbf3dc",
            [theme.breakpoints.up("md")]: { padding: "30px" },
            overflow: "auto",
            mx: 5,
          })}
        >
          <Box
            sx={{
              height: 500,
              width: "100%",
              "& .actions": {
                color: "text.secondary",
              },
              "& .textPrimary": {
                color: "text.primary",
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
