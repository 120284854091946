import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import Folder from "../../assets/images/folder.png";
import investor from "../../services/investor";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function CompanyInfo() {
  const [optionInvestor] = useState<any>({
    keyword: "company",
  });
  const listInvestor: any = useQuery(
    ["investor", optionInvestor],
    ({ queryKey }) => {
      return investor.getInvestors({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>{lang === "en" ? "Company Info" : "Info Perseroan"}</title>
      </Helmet>

      <PageName page={lang === "en" ? "COMPANY INFO" : "INFO PERSEROAN"} width={lang === "en" ? "460" : "420"} />

      <FormControl sx={{ position: "absolute", top: "20px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
        <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
        <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="id">Indonesia</MenuItem>
        </Select>
      </FormControl>

      <Box
        sx={(theme) => ({
          backgroundColor: "#fbf3dc", overflowY: 'scroll',
          [theme.breakpoints.up("md")]: {
            marginTop: "60px"
          },
        })}>
        <Grid container spacing={1}>
          {!listInvestor.isLoading ? listInvestor.data?.data.map((value:any, i:number) => (
            <Grid key={value.id} item xs={6} sm={4} md={3} xl={3}>
              <Box sx={{
                width: "100%",
                padding: "15px",
                textAlign: "center"
              }}>
                <a href={value.link} target="_blank" rel="noreferrer">
                  <img src={Folder} style={{ width: "80%" }} alt="folder" />
                </a>
                <Box sx={{
                  mt: 1,
                  backgroundColor: `${i % 2 === 0 ? "#d0d76d" : "#f499aa"}`,
                  borderRadius: "20px",
                  padding: "10px"
                }}>
                  <Typography
                    variant="h4"
                    letterSpacing={0}
                    fontFamily={"SaintRegusSemiBold"}
                    color={i % 2 === 0 ? "#fd7475" : "#59c2b4"}
                    sx={{ WebkitTextStroke: "0.7px black" }}>
                    {lang === "en" ? value.title : value.title_id}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )) : <></>}
        </Grid>
      </Box>
    </Box>
  );
}
