import axios from "axios";

import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getLinks = async (params: any) => {
  const url = endpoints.ENDPOINT_LINK;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const detailLink = async (id: number) => {
  if (!id) {
    return {};
  }
  const url = endpoints.ENDPOINT_SPECIFIC_LINK(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createLink = async (params: any) => {
  const url = endpoints.ENDPOINT_LINK;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editLink = async (params: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_LINK(params.id);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteLink = async (id: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_LINK(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getLinks,
  detailLink,
  createLink,
  editLink,
  deleteLink
};

export default service;