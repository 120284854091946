import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import product from "../../services/product";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function SubProductAdminCreate() {
  const { productid } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    name: yup.string().required("Name is a required field"),
    link: yup.string().required("Link URL Color is a required field"),
  });
  const form = useForm({
    defaultValues: {
      productid: productid,
      name: "",
      name_id: "",
      link: "",
    },
    resolver: yupResolver(schema),
  });

  const createSubProduct = useMutation("create_subproduct", product.createSubProduct, {
    onSuccess: (response) => {
      navigate("/admin/product/");

      notification.onOpen({
        message: response.message === "" ? "Sub Product was successfully created!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });



  const onSubmit = (values: any) => {
    createSubProduct.mutate({
      data: values,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Create Sub Product</title>
      </Helmet>

      <PageName page="PRODUCT" width="240" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <FormControl fullWidth margin="normal">
                <FormLabel required>Sub Product Name</FormLabel>
                <Controller
                  name="name"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel required>Nama Sub Product</FormLabel>
                <Controller
                  name="name_id"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <FormLabel required>Link URL</FormLabel>
                <Controller
                  name="link"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createSubProduct.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
