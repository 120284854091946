import axios from "axios";

import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getProducts = async (params: any) => {
  const url = endpoints.ENDPOINT_PRODUCT;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const detailProduct = async (id: number) => {
  if (!id) {
    return {};
  }
  const url = endpoints.ENDPOINT_SPECIFIC_PRODUCT(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createProduct = async (params: any) => {
  const url = endpoints.ENDPOINT_PRODUCT;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editProduct = async (params: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_PRODUCT(params?.id);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteProduct = async (id: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_PRODUCT(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getSubProducts = async (id: any) => {
  const url = endpoints.ENDPOINT_ALL_SUB_PRODUCT(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    }
  });

  return response;
};

const detailSubProduct = async (id: number) => {
  if (!id) {
    return {};
  }
  const url = endpoints.ENDPOINT_SPECIFIC_SUB_PRODUCT(id);

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createSubProduct = async (params: any) => {
  const url = endpoints.ENDPOINT_SUB_PRODUCT;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const editSubProduct = async (params: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_SUB_PRODUCT(params.data.id);

  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteSubProduct = async (id: any) => {
  const url = endpoints.ENDPOINT_SPECIFIC_SUB_PRODUCT(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getProducts,
  detailProduct,
  createProduct,
  editProduct,
  deleteProduct,
  getSubProducts,
  detailSubProduct,
  createSubProduct,
  editSubProduct,
  deleteSubProduct
};

export default service;