import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";
import Sidebar from "./dashboard/Drawer";
import Header from "./dashboard/Header";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    maxWidth: "none",
    marginRight: "auto",
    marginLeft: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 768,
    },
  },
}));

export default function DashboardLayout() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Header />
      <Box
        sx={{
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <Sidebar />
        <Box
          sx={(theme) => ({
            backgroundColor: "white",
            marginLeft: 0,

            [theme.breakpoints.up("md")]: {
              width: `calc(100% - 300px)`,
              marginLeft: "300px",
              marginTop: "20px"
            },
            [theme.breakpoints.down("sm")]: {
              marginTop: "48px"
            },
          })}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
