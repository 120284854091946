import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import service from "../../services/customerCare";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function CustomerCareLinkAdminCreate() {
  const { customercaresectionid } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    title: yup.string().required("Title is a required field"),
  });
  const form = useForm({
    defaultValues: {
      customercaresectionid: customercaresectionid,
      title: "",
      link: ""
    },
    resolver: yupResolver(schema),
  });

  const create = useMutation("create", service.createCustomerCareLink, {
    onSuccess: (response) => {
      navigate("/admin/customer-care/");

      notification.onOpen({
        message: response.message === "" ? "Customer Care Item was successfully created!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    create.mutate({
      data: values,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Create Customer Care Item</title>
      </Helmet>

      <PageName page="CUSTOMER CARE" width="380" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <FormControl fullWidth margin="normal">
                <FormLabel required>Item Name</FormLabel>
                <Controller
                  name="title"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
              
              <FormControl fullWidth margin="normal">
                <FormLabel>Item Link URL</FormLabel>
                <Controller
                  name="link"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={create.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
