import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import WhatsappLogo from "../../assets/images/social/app.png";
import InstagramLogo from "../../assets/images/social/instagram.png";
import LinkedinLogo from "../../assets/images/social/linkedin.png";
import EmailLogo from "../../assets/images/social/mail.png";
import TiktokLogo from "../../assets/images/social/tik-tok.png";
import TwitterLogo from "../../assets/images/social/twitter.png";
import service from "../../services/customerCare";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function CustomerCare() {
  const [option] = useState<any>({
    keyword: "",
  });
  const listCustomerCare: any = useQuery(
    ["customer-care", option],
    ({ queryKey }) => {
      return service.listCustomerCare({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>{lang === "en" ? "Customer Care" : "Layanan Pelanggan"}</title>
      </Helmet>

      <PageName page={lang === "en" ? "CUSTOMER CARE" : "LAYANAN PELANGGAN"} width={lang === "en" ? "380" : "500"} />

      <FormControl sx={{ position: "absolute", top: "20px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
        <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
        <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="id">Indonesia</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ mt: 10, backgroundColor: "#fbf3dc", padding: "20px" }}>
        <Grid container spacing={5}>
          {!listCustomerCare.isLoading ? listCustomerCare.data?.data.map((customerCare: any) => (
            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ backgroundColor: "#f4e39b", border: "2px solid black", paddingBottom: "15px" }}>
                <Box sx={{ mt: 2, backgroundColor: "#f499aa", textAlign: "center", color: "#f9fdfe", padding: "5px", borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                  <Typography
                    variant="h1"
                    fontSize="25px"
                    fontWeight="700"
                    fontFamily={"Poppins"}
                    letterSpacing={-2}
                    sx={{ textShadow: "-2px 2px #000", WebkitTextStroke: "1px black", overflowWrap: "break-word" }}>
                    {lang === "en" ? customerCare.title : customerCare.title_id}
                  </Typography>
                </Box>
                {customerCare.sections?.map((section: any) => (
                  <Box>
                    <Typography
                      variant="h2"
                      fontSize="25px"
                      fontFamily={"SaintRegus"}
                      sx={{ paddingLeft: "15px", paddingRight: "15px", paddingTop: "10px", paddingBottom: "3px" }}>
                      {lang === "en" ? section.title : section.title_id}
                    </Typography>
                    {section.links?.map((link: any) => (
                      <Typography sx={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        fontSize: "14px"
                      }}
                      >
                        {String(link.link) === "" ? link.title :
                          String(link.link).includes("instagram.com") ?
                            <a href={link.link} target="_blank" rel="noreferrer" className="customer-care">
                              <img src={InstagramLogo} alt="ig" style={{ width: "20px", marginRight: "5px", paddingTop: "5px" }} />{link.title}
                            </a> :
                            String(link.link).includes("tiktok.com") ?
                              <a href={link.link} target="_blank" rel="noreferrer" className="customer-care">
                                <img src={TiktokLogo} alt="tt" style={{ width: "20px", marginRight: "5px" }} />{link.title}
                              </a> :
                              String(link.link).includes("twitter.com") ?
                                <a href={link.link} target="_blank" rel="noreferrer" className="customer-care">
                                  <img src={TwitterLogo} alt="x" style={{ width: "20px", marginRight: "5px" }} />{link.title}
                                </a> :
                                String(link.link).includes("linkedin.com") ?
                                  <a href={link.link} target="_blank" rel="noreferrer" className="customer-care">
                                    <img src={LinkedinLogo} alt="linkedin" style={{ width: "20px", marginRight: "5px" }} />{link.title}
                                  </a> :
                                  String(link.link).includes("mailto") ?
                                    <a href={link.link} target="_blank" rel="noreferrer" className="customer-care">
                                      <img src={EmailLogo} alt="email" style={{ width: "20px", marginRight: "5px" }} />{link.title}
                                    </a> :
                                    String(link.link).includes("wa.me") ?
                                      <a href={link.link} target="_blank" rel="noreferrer" className="customer-care">
                                        <img src={WhatsappLogo} alt="wa" style={{ width: "20px", marginRight: "5px" }} />{link.title}
                                      </a> :
                                      <a href={link.link} target="_blank" rel="noreferrer" style={{ overflowWrap: "anywhere" }}> {link.title} </a>}
                      </Typography>
                    ))}
                  </Box>
                ))}
              </Box>
            </Grid>
          )) : <></>}
        </Grid>
      </Box>
    </Box>
  );
}
