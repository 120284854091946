import {
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import get from "lodash/get";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useAlert from "../../hooks/useAlert";
import useNotification from "../../hooks/useNotification";
import product from "../../services/product";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function ProductAdmin() {
  function getImageLink(link: string) {
    return `https://lh3.googleusercontent.com/d/${link.replace("https://drive.google.com/file/d/", "").replace("/view?usp=drive_link", "")}=s500?authuser=0`;
  }
  const [optionProduct] = useState<any>({
    keyword: "",
  });
  const listProduct: any = useQuery(
    ["investor", optionProduct],
    ({ queryKey }) => {
      return product.getProducts({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const alert = useAlert();
  const notification = useNotification();
  const deleteLink = useMutation("delete-link", product.deleteProduct, {
    onSuccess: () => {
      listProduct.refetch();
      notification.onOpen({
        message: "Product was successfully deleted!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const onDelete = (id: number) => {
    alert.onClose();
    deleteLink.mutate(id);
  };
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete this Product?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const navigate = useNavigate();
  const handleOnCellClick = (params: GridCellParams) => {
    const { row } = params;
    navigate(`/admin/product/${row.id}`, { state: row });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Admin Product</title>
      </Helmet>

      <PageName page="PRODUCT" width="240" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
          <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight
            loading={listProduct.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listProduct, "data.data", [])}
            rowCount={get(listProduct, "data.data", []).length}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "name",
                headerName: "Product Name",
                flex: 1,
                filterable: false,
                minWidth: 200,

                renderCell: (params) => {
                  return (
                    <Stack onClick={() => handleOnCellClick(params)}>
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "titleColor",
                headerName: "Title Color",
                flex: 1,
                filterable: false,
                minWidth: 100,

                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "frameColor",
                headerName: "Frame Color",
                flex: 1,
                filterable: false,
                minWidth: 100,

                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "image",
                headerName: "Image Source",
                flex: 1,
                filterable: false,
                minWidth: 200,

                renderCell: (params) => {
                  return (
                    <Stack>
                      <a href={params.value} target="_blank" rel="noreferrer">Source</a>
                      <br></br>
                      <Typography>Preview:</Typography>
                      <img src={getImageLink(params.value)} style={{ width: "50%" }} alt="Banner" />
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/admin/product/edit-product/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onClickDelete(params.row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
        <Box sx={{ mt: 2, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/admin/product/add-product"
            >
              Add Product
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
