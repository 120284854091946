export const API_URL = process.env.REACT_APP_BACKEND_URL
const endpoints = {
  // Auth
  API: API_URL + '/api/v1',
  SIGN_IN: API_URL + "/api/v1/auth/signin",
  PROFILE: (userId: any) => API_URL + `/api/v1/users/${userId}`,
  FORGOT_PASSWORD: API_URL + "/api/v1/admin/forgot-password",
  RESET_PASSWORD: API_URL + "/api/v1/admin/reset-password",
  EDIT_PROFILE: (userId: any) => API_URL + `/api/v1/users/${userId}`,
  CHANGE_PASSWORD: API_URL + "/api/v1/users/change-password",

  // Role
  VIEW_LIST_ROLE: API_URL + "/api/v1/roles",

  // Users
  VIEW_LIST_USER: API_URL + "/api/v1/users",
  SEARCH_LIST_USER: API_URL + "/api/v1/admin/search-users",
  CREATE_USER: API_URL + "/api/v1/users",
  VIEW_USER: (viewId: any) => API_URL + `/api/v1/users/${viewId}`,

  // Links
  ENDPOINT_LINK: API_URL + "/api/v1/link",
  ENDPOINT_SPECIFIC_LINK:  (id: any) => API_URL + `/api/v1/link/${id}`,

  // Activities
  ENDPOINT_ACTIVITIES: API_URL + "/api/v1/activity",
  ENDPOINT_ACTIVITY_YEARS: API_URL + "/api/v1/activity/years",
  ENDPOINT_SPECIFIC_ACTIVITIES:  (id: any) => API_URL + `/api/v1/activity/${id}`,

  // Investor
  ENDPOINT_INVESTOR: API_URL + "/api/v1/investor",
  ENDPOINT_SPECIFIC_INVESTOR:  (id: any) => API_URL + `/api/v1/investor/${id}`,

  // Product
  ENDPOINT_PRODUCT: API_URL + "/api/v1/product",
  ENDPOINT_SPECIFIC_PRODUCT:  (id: any) => API_URL + `/api/v1/product/${id}`,
  ENDPOINT_SUB_PRODUCT: API_URL + "/api/v1/product/sub",
  ENDPOINT_ALL_SUB_PRODUCT:  (id: any) => API_URL + `/api/v1/product/suball/${id}`,
  ENDPOINT_SPECIFIC_SUB_PRODUCT:  (id: any) => API_URL + `/api/v1/product/sub/${id}`,

  // Customer Care
  ENDPOINT_CUSTOMER_CARE: API_URL + "/api/v1/customercare",
  ENDPOINT_SPECIFIC_CUSTOMER_CARE:  (id: any) => API_URL + `/api/v1/customercare/${id}`,
  ENDPOINT_CUSTOMER_CARE_SECTION: API_URL + "/api/v1/customercare/section",
  ENDPOINT_SPECIFIC_CUSTOMER_CARE_SECTION:  (id: any) => API_URL + `/api/v1/customercare/section/${id}`,
  ENDPOINT_CUSTOMER_CARE_LINK: API_URL + "/api/v1/customercare/link",
  ENDPOINT_SPECIFIC_CUSTOMER_CARE_LINK:  (id: any) => API_URL + `/api/v1/customercare/link/${id}`,
};

export default endpoints;
