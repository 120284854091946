import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { get } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import service from "../../services/activities";
import queryDefault from "../../utils/queryDefault";
import { Helmet } from "react-helmet-async";
import PageName from "../components/PageName";

export default function ActivitiesAdminCreateEdit() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    type: yup.string().required("Type is a required field"),
    month: yup.number().required("Month is a required field"),
    year: yup.number().required("Year is a required field"),
    link: yup.string().required("Image source is a required field"),
    order: yup.number().required("Order is a required field"),
  });
  const currentActivity = useQuery("view-activity", () => service.detailActivity(Number(id)));
  const now = new Date()
  const form = useForm({
    defaultValues: {
      type: "",
      month: now.getMonth()+1,
      year: now.getFullYear(),
      link: "",
      order: 0
    },
    resolver: yupResolver(schema),
  });
  const yearOptions:number[] = []
  for (var i = 0; i < 10; i++) {
    yearOptions.push(now.getFullYear() - i);
  }

  const createActivity = useMutation("create-activity", service.createActivity, {
    onSuccess: (response) => {
      navigate("/admin/activities/");

      notification.onOpen({
        message: response.message === "" ? "Activity was successfully created!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateActivity = useMutation("update-activity", service.editActivity, {
    onSuccess: (response) => {
      navigate("/admin/activities/");

      notification.onOpen({
        message: response.message === "" ? "Activity was successfully updated!" : response.message,
        type: "success",
        position: "top",
      });
    },
    onError: (error: any) => {
      notification.onOpen({
        message: error?.response?.data?.message === undefined ? "Something went wrong" : error?.response?.data?.message,
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    if (id) {
      updateActivity.mutate({
        data: {
          ...values,
          id: id
        }
      })
    } else {
      createActivity.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (id) {
      form.setValue("type", get(currentActivity, "data.data.type"));
      form.setValue("month", get(currentActivity, "data.data.month"));
      form.setValue("year", get(currentActivity, "data.data.year"));
      form.setValue("link", get(currentActivity, "data.data.link"));
      form.setValue("order", get(currentActivity, "data.data.order"));
    }
  }, [currentActivity, form, id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Create or Edit Activites</title>
      </Helmet>

      <PageName page="ACTIVITIES" width="255" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        {!currentActivity.isLoading ? (
          <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel required>Type</FormLabel>
                  <Controller
                    name="type"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Select
                        {...field}
                        labelId="select-type-label"
                        id="select-type"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        label="Age"
                      >
                        <MenuItem value={"partnership"}>Partnership</MenuItem>
                        <MenuItem value={"community"}>Community</MenuItem>
                        <MenuItem value={"workshop"}>Workshop</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel required>Month</FormLabel>
                  <Controller
                    name="month"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Select
                        {...field}
                        labelId="select-month-label"
                        id="select-month"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        label="Month"
                      >
                        <MenuItem value={1}>January</MenuItem>
                        <MenuItem value={2}>February</MenuItem>
                        <MenuItem value={3}>March</MenuItem>
                        <MenuItem value={4}>April</MenuItem>
                        <MenuItem value={5}>May</MenuItem>
                        <MenuItem value={6}>June</MenuItem>
                        <MenuItem value={7}>July</MenuItem>
                        <MenuItem value={8}>August</MenuItem>
                        <MenuItem value={9}>September</MenuItem>
                        <MenuItem value={10}>October</MenuItem>
                        <MenuItem value={11}>November</MenuItem>
                        <MenuItem value={12}>December</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel required>Year</FormLabel>
                  <Controller
                    name="year"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Select
                        {...field}
                        labelId="select-year-label"
                        id="select-year"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        label="Year"
                      >
                        {yearOptions.map((year) => {
                          return (<MenuItem value={year}>{year}</MenuItem>)
                        })}
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel required>Image Source</FormLabel>
                  <Controller
                    name="link"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel required>Order</FormLabel>
                  <Controller
                    name="order"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                        type="number"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>) : <></>}
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={id ? updateActivity.isLoading : createActivity.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
