import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import product from "../../services/product";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function Product() {
  function getImageLink(link: string) {
    return `https://lh3.googleusercontent.com/d/${link.replace("https://drive.google.com/file/d/", "").replace("/view?usp=drive_link", "")}=s1000?authuser=0`;
  }

  const [optionProduct] = useState<any>({
    keyword: "",
  });
  const listProduct: any = useQuery(
    ["product", optionProduct],
    ({ queryKey }) => {
      return product.getProducts({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>{lang === "en" ? "Product" : "Produk"}</title>
      </Helmet>

      <PageName page={lang === "en" ? "PRODUCT" : "PRODUK"} width={lang === "en" ? "240" : "210"} />

      <FormControl sx={{ position: "absolute", top: "-60px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
        <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
        <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="id">Indonesia</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ mt: 15, backgroundColor: "#fbf3dc", padding: "30px" }}>
        <Grid container spacing={10}>
          {!listProduct.isLoading ? listProduct.data?.data.map((product: any) => (
            <Grid item xs={12} sm={6} lg={4}>
              <Box className="product" sx={{
                backgroundColor: "#eae5db",
                border: "4px solid black",
                paddingBottom: "15px",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: "40vh",
                height: "100%"
              }}>
                <Box className="product-header" sx={{
                  backgroundColor: product.titleColor,
                  textAlign: "center",
                  color: "#000000",
                  padding: "5px",
                  borderBottom: "4px solid black",
                  borderRadius: "10px 10px 0 0",
                  width: "100%",
                  display: "flex"
                }}>
                  <Typography
                    sx={{ margin: "auto" }}
                    variant="h1"
                    fontSize="35px"
                    fontWeight="700"
                    fontFamily={"SaintRegus"}>
                    {product.name.replace("+", "")}
                    {product.name.includes("+") ?
                      <span style={{
                        fontFamily: "Arial",
                        fontWeight: "bolder"
                      }}>+</span> :
                      <></>}
                  </Typography>
                </Box>
                <Box sx={{
                  mt: 2,
                  width: "80%",
                  height: "250px",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  border: "3px solid black",
                  borderRadius: "100px 100px 20px 20px",
                  backgroundColor: product.frameColor
                }}>
                  <Box sx={{
                    mt: 2,
                    margin: "auto",
                    width: "95%",
                    height: "95%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    border: "3px solid black",
                    borderRadius: "100px 100px 20px 20px"
                  }}>
                    <img src={getImageLink(product.image)} alt="product"
                      style={{
                        width: "100%",
                        margin: "auto"
                      }}
                    />
                  </Box>
                </Box>
                {product.subProducts.map((subProduct: any) => (
                  <Box sx={{
                    mt: 2,
                    padding: "5px",
                    backgroundColor: "#5286c5",
                    border: "3px solid black",
                    width: "70%",
                    textAlign: "center",
                    borderRadius: "20px",
                    boxShadow: "-2px 2px 0px 0px rgba(0,0,0,1)",
                    cursor: "pointer"
                  }}>
                    <Typography
                      variant="h1"
                      fontSize="20px"
                      fontWeight="700"
                      fontFamily={"Poppins"}>
                      <a href={subProduct.link}
                        target="_blank"
                        rel="noreferrer"
                        className="aa">
                        {lang === "en" ? subProduct.name : subProduct.name_id}
                      </a>
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          )) : <></>}
        </Grid>
      </Box>
    </Box>
  );
}
